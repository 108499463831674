<template>
  <div>
    <div class="banner-wrapper">
      <div class="banner-wrapper__header">
        <telia-heading class="banner-wrapper__title" tag="h1" variant="display-50">{{
          t("heading")
        }}</telia-heading>
        <telia-p variant="preamble-200">{{ t("preamble") }}</telia-p>
      </div>
    </div>
    <div>
      <b2x-stepper
        :steps="
          JSON.stringify([
            {
              displayName: t('preAuthenticationStep.stepName'),
              revisitable: false,
            },
            {
              displayName: t('mainMenuStep.stepName'),
              revisitable: true,
            },
            {
              displayName: t('additionalDataAndConfirmationStep.stepName'),
              revisitable: false,
            },
          ])
        "
        :currentStep="currentStep"
        @stepChange="(event) => $emit('go-to-step', event.detail)"
        ref="stepper"
      >
        <div slot="0" class="step-container">
          <step-frame>
            <slot name="pre-authentication"
              ><!-- (content to be overridden by parent component )--></slot
            >
          </step-frame>
        </div>
        <div slot="1" class="step-container">
          <step-frame>
            <slot name="main-menu"><!-- (content to be overridden by parent component )--></slot>
          </step-frame>
        </div>
        <div slot="2" class="step-container">
          <telia-grid>
            <telia-row>
              <telia-col width="0" width-lg="2"></telia-col>
              <telia-col width="12" width-lg="8">
                <telia-link @click="$emit('go-to-step', 1)">
                  <telia-icon class="back-link" name="arrow-left" size="sm" /><telia-visually-hidden
                    >{{ t("goBackAlly") }}</telia-visually-hidden
                  >{{ t("goBack") }}
                </telia-link>
              </telia-col>
              <telia-col width="0" width-lg="2"></telia-col></telia-row
          ></telia-grid>
          <step-frame>
            <slot name="additional-data-and-confirmation"
              ><!-- (content to be overridden by parent component )--></slot
            >
          </step-frame>
        </div>
      </b2x-stepper>
    </div>
  </div>
</template>

<script setup lang="ts">
import { translateMixin, translateSetup } from "../locale";
import stepFrame from "./step-frame.vue";

translateSetup(["mybusiness"]);
const t = translateMixin.methods.t;

defineProps({
  currentStep: { type: Number, required: true },
});
</script>

<style lang="scss" scoped>
@import "~@teliads/components/foundations/colors/variables";
@import "@teliads/components/foundations/spacing/tokens";

.banner-wrapper {
  text-align: center;
  background: $telia-purple-850;
  color: $telia-purple-100;
  display: flex;
  justify-content: center;
  &__header {
    padding: $telia-spacing-96 $telia-spacing-16 $telia-spacing-64;
    background-color: $telia-purple-850;
  }
  &__title {
    margin-bottom: $telia-spacing-16;
  }
}

.stepper {
  background: $telia-gray-200;
}

.step-container {
  padding-top: $telia-spacing-32;
}

telia-icon.back-link {
  vertical-align: text-bottom;
  margin-right: $telia-spacing-4;
}
</style>
