<template>
  <div>
    <telia-p>
      {{ t("mainMenuStep.searchOrganisation.introduction") }}
    </telia-p>
    <b2b-search-form
      @submitSearch="(event) => search(event.detail.text)"
      @failedValidation="() => {}"
      :loading="'false'"
      :input-label="t('mainMenuStep.searchOrganisation.inputLabel')"
      :button-label="t('mainMenuStep.searchOrganisation.searchButton')"
      :validation-error-message="t('mainMenuStep.searchOrganisation.badNumber')"
      auto-focus="false"
      ref="searchForm"
    />

    <div aria-live="assertive">
      <div v-if="searchState === 'SEARCHING'" class="searching">
        <div class="spinner">
          <b2x-spinner size="large" /><telia-visually-hidden>{{
            t("mainMenuStep.searchOrganisation.searching")
          }}</telia-visually-hidden>
        </div>
      </div>

      <div v-else-if="searchState === 'SUCCESS' && searchResult" class="match">
        <organisation-button
          :organisation="searchResult"
          @selected-organisation="(organisation) => $emit('selected-organisation', organisation)"
        ></organisation-button>
      </div>

      <div v-else-if="searchState === 'SUCCESS'" class="no-match">
        {{
          t("mainMenuStep.searchOrganisation.notFound", { organisationNumber: searchInput ?? "" })
        }}
      </div>
      <div v-else-if="searchState === 'ERROR'" class="unexpected-error">
        {{ t("mainMenuStep.searchOrganisation.error") }}
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { logError } from "@telia/b2x-logging";
import { translateMixin, translateSetup } from "../../../../locale";
import { onMounted, ref, Ref } from "vue";
import {
  isOrganisationNumberValid,
  searchForOrganisation,
} from "../../../../services/organisation-service";
import type { Organisation, SearchPromises } from "../../../../services/organisation-service";
import { Components } from "@telia/b2b-search-form";
import organisationButton from "../organisation-button.vue";
import * as analytics from "@telia/b2b-web-analytics-wrapper";

translateSetup(["mybusiness"]);
const t = translateMixin.methods.t;

// state of the actual search and search results, not of the input box
let searchState: Ref<"PRISTINE" | "SEARCHING" | "SUCCESS" | "ERROR"> = ref("PRISTINE");

let searchInput: Ref<string | null> = ref(null);

// doesn't have to be Ref, and cannot be because that breaks Cancel
let searchPromises: SearchPromises | null = null;

let searchResult: Ref<Organisation | null> = ref(null);

// declare a ref to hold the element reference
// the name must match template ref value
const searchForm: Ref<Components.B2bSearchForm | null> = ref(null);

onMounted(() => {
  // set the validate function, functions cannot be set through HTML attributes, must be set programmatically
  if (searchForm.value) {
    searchForm.value.validate = isOrganisationNumberValid;
  }
});

const search = async (searchText: string) => {
  searchInput.value = searchText;
  searchState.value = "SEARCHING";

  // cancel ongoing search, if any
  if (searchPromises?.cancelable?.cancel) {
    searchPromises.cancelable.cancel();
  }

  // start a new search
  searchPromises = searchForOrganisation(searchText);

  // do this when the search has completed or been cancelled
  // (cancelled searches will cause rejections)
  searchPromises.result
    .then((result) => {
      searchPromises = null;
      searchResult.value = result;
      searchState.value = "SUCCESS";
    })
    .catch((error) => {
      // completely ignore CancelErrors that happens anytime a search is cancelled
      if (!error?.isCancelled) {
        logError("b2b-organisation-registration", "Error searching for organisation");
        searchPromises = null;
        searchResult.value = null;
        searchState.value = "ERROR";
        analytics.trackEvent(
          analytics.category.REGISTER_ACCOUNT,
          analytics.action.ERROR,
          analytics.label.COMPANY_NOT_FOUND
        );
      }
    });
};

// expose to unit tests
defineExpose({ search });
</script>

<style lang="scss" scoped>
@import "~@teliads/components/foundations/spacing/variables";

telia-p {
  margin-bottom: $telia-spacing-16;
}

.searching {
  text-align: center;
}

.spinner {
  display: inline-block;
}
</style>
