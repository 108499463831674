<template>
  <div>
    <div v-if="!loading">
      <div v-if="suggestedOrganisations.length">
        <telia-p variant="preamble-100" class="heading">{{
          t("mainMenuStep.suggestedOrganisations.heading")
        }}</telia-p>
        <ul class="suggestions">
          <li
            v-for="(organisation, index) in suggestedOrganisations"
            :key="index"
            class="suggestion-button"
          >
            <organisation-button
              :organisation="organisation"
              @selected-organisation="
                (organisation) => $emit('selected-organisation', organisation)
              "
            ></organisation-button>
          </li>
        </ul>
      </div>
      <!--
        If no suggestions or error, show nothing.
        Check git log for a working status message!
      -->
    </div>
    <div v-else>
      <telia-skeleton class="heading-skeleton" />
      <telia-skeleton class="suggestion-skeleton" />
    </div>
  </div>
</template>

<script setup lang="ts">
import { getSuggestedOrganisations } from "../../../../services/organisation-service";
import { translateMixin, translateSetup } from "../../../../locale";
import organisationButton from "../organisation-button.vue";
import { ref, Ref } from "vue";
import type { Organisation } from "../../../../services/organisation-service";
import { logError } from "@telia/b2x-logging";

translateSetup(["mybusiness"]);
const t = translateMixin.methods.t;

let loading: Ref<boolean> = ref(true);
let suggestedOrganisations: Ref<Array<Organisation>> = ref([]);

let suggestedOrganisationsPromise = getSuggestedOrganisations();
suggestedOrganisationsPromise
  .then((result) => {
    suggestedOrganisations.value = result;
  })
  .catch(() => {
    logError("b2b-organisation-registration", "Error fetching suggested organisations");
  })
  .finally(() => {
    loading.value = false;
  });
</script>

<style lang="scss" scoped>
@import "~@teliads/components/foundations/spacing/variables";
@import "~@teliads/components/foundations/borders/variables";

.heading,
.heading-skeleton {
  margin-bottom: $telia-spacing-32;
}

.suggestions,
.suggestion-skeleton {
  margin-bottom: $telia-spacing-32;
}

.heading-skeleton {
  width: 80%;
  height: 2.9rem;
}

.suggestion-skeleton {
  width: 100%;
  height: 9rem;
  border-radius: $telia-border-radius-8;
  overflow: hidden;
}

.suggestion-button {
  list-style: none;

  &:not(:last-child) {
    margin-bottom: $telia-spacing-16;
  }
}
</style>
