import { corpCustomerRegistration, corpLogin } from "@telia/b2b-rest-client";

import { B2B_START_PAGE_URL } from "@telia/b2b-utils";

import { GetUserResponse } from "@telia/b2b-rest-client/dist/corp-customer-registration/models/GetUserResponse";

export type PrecheckStatus = "OK" | "RESTRICTED_LOGIN" | "CLOSED" | "NOT_LOGGED_IN";

export const precheck = async (): Promise<PrecheckStatus> => {
  // debug code to override the value during local development
  if (window.localStorage.getItem("telia-mock-precheck")) {
    await new Promise((resolve) => setTimeout(resolve, 2000));
    return window.localStorage.getItem("telia-mock-precheck") as PrecheckStatus;
  }
  // end of debug code

  return (await corpCustomerRegistration.ApplicationRequestV2ControllerService.precheck()).status;
};

export const getUserInformation = async (): Promise<GetUserResponse> => {
  // debug code to override the value during local development
  if (window.localStorage.getItem("telia-mock-userinfo") === "1") {
    await new Promise((resolve) => setTimeout(resolve, 2000));
    return { name: "John Doe***", email: "noreply@telia.se", phoneNumber: "+46 701 74 06 05" }; // fake number from PTS
  } else if (window.localStorage.getItem("telia-mock-userinfo") === "0") {
    await new Promise((resolve) => setTimeout(resolve, 2000));
    throw new Error("mock fail");
  }
  // end of debug code

  return corpCustomerRegistration.UserControllerService.getUser();
};

export const logOut = async () => {
  const response = await corpLogin.LogoutControllerService.initiateLogout();
  window.location.assign(response.redirectUrl ?? B2B_START_PAGE_URL);
};
