<template>
  <telia-p>
    <div v-if="totalState === 'LOADING'"></div>

    <post-authentication-flow
      v-else-if="totalState === 'FLOW'"
      @finish-flow="($event: SubmitResultFromFlow) => finishFlow($event)"
      :fully-authenticated="fullyAuthenticated"
      :scopes="scopes as Scope[]"
      :applications="applications as Organisation[]"
    />

    <!--
      User has logged in with password (not with BankID), and therefore can not see suggestions or search orgs.
      User has no scopes, and no ongoing applications.
      I.e, user can not do anything.
      User must log out and log in with BankID to get more options.
      There is no way to upgrade the account (because at least one scope is required for that).
    -->
    <b2x-result-message
      v-else-if="totalState === 'RESTRICTED_LOGIN_WITHOUT_OPTIONS'"
      graphic="error"
      :heading="t('restrictedLogin.noOptions_noScopes_mustLogInWithBankId.heading')"
      :complete-description="
        t('restrictedLogin.noOptions_noScopes_mustLogInWithBankId.completeDescription')
      "
      action-type="button"
      :action-label="t('common.logOutButton')"
      action-left-icon="logout"
      @buttonClick="logOut()"
    />

    <!--
      application submitted and auto-approved
      Link directly to the new scope (if we got one)
    -->
    <b2x-result-message
      v-else-if="totalState === 'APPLICATION_APPROVED'"
      graphic="getting-started"
      :heading="t('applicationSubmitted.approved.heading')"
      :complete-description="t('applicationSubmitted.approved.completeDescription')"
      action-type="cta-link"
      :action-label="t('applicationSubmitted.approved.loggedInMyBusinessLink')"
      :link-href="createdScopeUrl"
    />

    <!-- application submitted and pending -->
    <b2x-result-message
      v-else-if="totalState === 'APPLICATION_ACCEPTED'"
      graphic="success"
      :heading="t('applicationSubmitted.pending.heading')"
      :complete-description="t('applicationSubmitted.pending.completeDescription')"
      action-type="button"
      :action-label="t('common.logOutButton')"
      action-left-icon="logout"
      @buttonClick="logOut()"
    />

    <!-- application submitted and auto-rejected - always because the company already exists -->
    <b2x-result-message
      v-else-if="totalState === 'APPLICATION_REJECTED'"
      graphic="error"
      :heading="t('applicationSubmitted.rejectedBecauseAlreadyInMyBusiness.heading')"
      :complete-description="
        t('applicationSubmitted.rejectedBecauseAlreadyInMyBusiness.completeDescription')
      "
      action-type="button"
      :action-label="t('common.logOutButton')"
      action-left-icon="logout"
      @buttonClick="logOut()"
    />

    <!--
      Unexpected error when submitting
      Note - init errors should be caught in b2b-organisation-registration
    -->
    <b2x-result-message
      v-else
      graphic="technical-error"
      :heading="t('applicationSubmitted.error.heading')"
      :complete-description="t('applicationSubmitted.error.completeDescription')"
      action-type="button"
      :action-label="t('applicationSubmitted.error.restartFlowButton')"
      action-left-icon="arrow-left"
      @buttonClick="restartFlow()"
    />
  </telia-p>
</template>

<script setup lang="ts">
import { computed, ref, Ref } from "vue";
import { translateMixin, translateSetup } from "../locale";
import * as analytics from "@telia/b2b-web-analytics-wrapper";

import { B2B_BASE_URL } from "@telia/b2b-utils";
import { logError } from "@telia/b2x-logging";

import type { Scope, Organisation, SubmitResultFromFlow } from "../services/organisation-service";
import { getExistingScopes, getPendingApplications } from "../services/organisation-service";
import { logOut } from "../services/user-service";

import postAuthenticationFlow from "./post-authentication-flow.vue";
import {
  getGa4OrganisationRegistrationParams,
  getGa4StepParams,
  trackGa4,
} from "@telia/b2b-analytics";

translateSetup(["mybusiness"]);
const t = translateMixin.methods.t;

const props = defineProps({
  fullyAuthenticated: { type: Boolean, required: true },
});

let scopes: Ref<Array<Scope> | "LOADING"> = ref("LOADING");
let applications: Ref<Array<Organisation> | "LOADING"> = ref("LOADING");
let submitResult: Ref<SubmitResultFromFlow | null> = ref(null);

getExistingScopes()
  .then((scopesResult) => {
    scopes.value = scopesResult;
  })
  .catch(() => {
    scopes.value = [];
    logError("b2b-organisation-registration", "Failed to get scopes, acting as if there are none");
  });

getPendingApplications()
  .then((applicationsResult) => {
    applications.value = applicationsResult;
  })
  .catch(() => {
    applications.value = [];
    logError(
      "b2b-organisation-registration",
      "Failed to get ongoing application, acting as if there are none"
    );
  });

const totalState = computed(
  (): "LOADING" | "FLOW" | "RESTRICTED_LOGIN_WITHOUT_OPTIONS" | SubmitResultFromFlow["result"] => {
    if (submitResult.value) {
      // user has submitted and received a response, end of flow
      return submitResult.value.result;
    } else if (scopes.value === "LOADING" || applications.value === "LOADING") {
      // loading scopes and applications before showing main menu
      return "LOADING";
    } else if (
      props.fullyAuthenticated ||
      scopes.value.length > 0 ||
      applications.value.length > 0
    ) {
      // show step 2 and 3
      return "FLOW";
    } else {
      // nothing to show in main menu, abort flow
      return "RESTRICTED_LOGIN_WITHOUT_OPTIONS";
    }
  }
);

const finishFlow: Ref<(result: SubmitResultFromFlow) => void> = ref((result) => {
  trackResult(result.result);
  submitResult.value = result;
});

const restartFlow: Ref<() => void> = ref(() => {
  // Cause totalState to revert to FLOW.
  // Since we removed the child component "post-authentication-flow" when showing
  // the result message, all user choices done within the flow should have been reset
  submitResult.value = null;
});
const trackResult = (result: SubmitResultFromFlow["result"]) => {
  switch (result) {
    case "APPLICATION_REJECTED":
      analytics.trackEvent(
        analytics.category.REGISTER_ACCOUNT,
        analytics.action.ERROR,
        analytics.label.COMPANY_ALREADY_EXISTS
      );

      trackGa4("account_registration_fail", {
        ...getGa4OrganisationRegistrationParams(),
        error_message: "company_already_exists",
        error_type: "registration_submit",
      });
      break;
    case "APPLICATION_ACCEPTED":
      analytics.trackEvent(
        analytics.category.REGISTER_ACCOUNT,
        analytics.action.APPLIED,
        analytics.label.BANK_ID
      );

      trackGa4("account_registration_complete", {
        ...getGa4OrganisationRegistrationParams(),
        ...getGa4StepParams("account_registration_complete", undefined, "manual"),
      });
      break;
    case "APPLICATION_APPROVED":
      analytics.trackEvent(
        analytics.category.REGISTER_ACCOUNT,
        analytics.action.COMPLETED,
        analytics.label.BANK_ID
      );

      trackGa4("account_registration_complete", {
        ...getGa4OrganisationRegistrationParams(),
        ...getGa4StepParams("account_registration_complete", undefined, "completed"),
      });
      break;
    case "UNEXPECTED_ERROR":
      analytics.trackEvent(
        analytics.category.REGISTER_ACCOUNT,
        analytics.action.ERROR,
        analytics.label.FAIL
      );

      trackGa4("account_registration_fail", {
        ...getGa4OrganisationRegistrationParams(),
        error_message: "fail",
        error_type: "registration_submit",
      });
  }
};

const createdScopeUrl = computed((): string => {
  return (
    B2B_BASE_URL +
    (submitResult.value?.result === "APPLICATION_APPROVED" && submitResult.value?.scopeId
      ? "/" + submitResult.value?.scopeId
      : "")
  );
});
</script>

<style lang="scss" scoped></style>
