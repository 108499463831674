import { corpCustomerRegistration, corpScopeInformation } from "@telia/b2b-rest-client";
import { CancelablePromise } from "@telia/b2b-rest-client/dist/corp-customer-registration";
import type { ApiError } from "@telia/b2b-rest-client/dist/corp-customer-registration";

export type Organisation = {
  name: string;
  organisationNumber: string;
};

export type Scope = {
  displayName: string;
  id: string;
};

export type SearchPromises = {
  cancelable: CancelablePromise<corpCustomerRegistration.SearchOrganisationResponse>;
  result: Promise<Organisation | null>;
};

type AtLeastOneInvalidField =
  | {
      invalidEmailAddress: true;
      invalidPhoneNumber: true;
    }
  | {
      invalidEmailAddress: true;
      invalidPhoneNumber: false;
    }
  | {
      invalidEmailAddress: false;
      invalidPhoneNumber: true;
    };

export type SubmitResultFromBackend =
  | {
      result: "APPLICATION_APPROVED";
      scopeId?: string; // SHOULD get this, but handle also it missing
    }
  | {
      result: "APPLICATION_REJECTED" | "APPLICATION_ACCEPTED";
    }
  | ({
      result: "INVALID_INPUT";
    } & AtLeastOneInvalidField);

export type SubmitResultFromFlow =
  | {
      result: "APPLICATION_APPROVED";
      scopeId?: string; // SHOULD get this, but handle also it missing
    }
  | {
      result: "APPLICATION_REJECTED" | "APPLICATION_ACCEPTED" | "UNEXPECTED_ERROR";
    };

export const mapSuggestedOrganisations = (backendOrganisations: Organisation[]) =>
  backendOrganisations.map((backendOrganisation) => ({
    name: backendOrganisation.name ?? "",
    organisationNumber: backendOrganisation.organisationNumber ?? "",
  }));

export const mapAvailableScopes = (
  availableScopes: Array<corpScopeInformation.Scope>
): Array<Scope> =>
  availableScopes
    .map((scope) => {
      if (scope.id && scope.name) {
        return {
          id: scope.id,
          displayName: scope.name,
        };
      } else {
        throw new Error("Scope missing id or name");
      }
    })
    .sort((a: Scope, b: Scope) =>
      // sort according to Swedish rules regardless of selected language
      // https://telianorge.slack.com/archives/C04CG7N6PPZ/p1688549591966349
      a.displayName.localeCompare(b.displayName, "sv-SE", {
        sensitivity: "base",
      })
    );

export const mapPendingApplications = (
  openApplicationRequeests: corpCustomerRegistration.ApplicationRequest[]
): Array<Organisation> =>
  openApplicationRequeests
    .map((application) => {
      if (application.organizationNumber && application.organizationName) {
        return {
          organisationNumber: application.organizationNumber,
          name: application.organizationName,
        };
      } else {
        throw new Error("Ongoing application missing name or number");
      }
    })
    .sort((a: Organisation, b: Organisation) =>
      // sort according to Swedish rules regardless of selected language
      // https://telianorge.slack.com/archives/C04CG7N6PPZ/p1688549591966349
      a.name.localeCompare(b.name, "sv-SE", {
        sensitivity: "base",
      })
    );

/**
 * Returns true if matches "NNNNNN-NNNN" or "NNNNNN NNNN" or "NNNNNNNNNN"
 * @param organisationNumber
 * @returns
 */
export const isOrganisationNumberValid = (organisationNumber: string) =>
  !!organisationNumber.trim().match(/^(\d{6}[- ]?\d{4})$/);

export const normalizeOrganisation = (organisationNumber: string) =>
  // replaceAll would be nicer, but not supported by current build target
  organisationNumber.trim().split("-").join("").split(" ").join("");

export const mapOrganisationSearchResult = (
  backendOrganisationWrapper: corpCustomerRegistration.SearchOrganisationResponse
): Organisation | null => {
  if (!backendOrganisationWrapper.organisation) {
    // represents no match
    return null;
  } else if (
    backendOrganisationWrapper.organisation?.name &&
    backendOrganisationWrapper.organisation?.organisationNumber
  ) {
    return {
      name: backendOrganisationWrapper.organisation?.name,
      organisationNumber: backendOrganisationWrapper.organisation?.organisationNumber,
    };
  } else {
    throw new Error("Organisation missing name or number");
  }
};

export const mapSubmitApplicationSuccess = (
  backendResponse: corpCustomerRegistration.ApplicationRegistrationResponseV2
): SubmitResultFromBackend => {
  // NOTE: APPLICATION_REJECTED is currently ALWAYS because the company already exists in MyBusiness.

  if (backendResponse.registerStatus === "APPLICATION_APPROVED") {
    return { result: backendResponse.registerStatus, scopeId: backendResponse.scopeId };
  } else {
    return { result: backendResponse.registerStatus };
  }
};

export const mapSubmitApplicationError = (error: unknown): SubmitResultFromBackend => {
  const apiError = error as ApiError;
  if (apiError?.status === 400) {
    const invalidEmailAddress =
      apiError?.body?.translationKey?.includes("INVALID_EMAIL_ADDRESS") ?? false;
    const invalidPhoneNumber =
      apiError?.body?.translationKey?.includes("INVALID_PHONE_NUMBER") ?? false;
    if (invalidEmailAddress || invalidPhoneNumber) {
      return {
        result: "INVALID_INPUT",
        invalidEmailAddress: invalidEmailAddress,
        invalidPhoneNumber: invalidPhoneNumber,
      };
    } else {
      throw new Error("Unexpected 400 error with no known validation fields");
    }
  }
  throw new Error("Unexpected backend error");
};
