<template>
  <div>
    <div v-if="loading">
      <telia-skeleton class="heading-skeleton" />
      <telia-skeleton class="accordion-skeleton" />
    </div>
    <div v-else>
      <telia-heading tag="h2" variant="title-300">{{
        personName
          ? t("mainMenuStep.heading", { name: personName ?? "" })
          : t("mainMenuStep.fallbackHeading")
      }}</telia-heading>
      <suggested-organisations
        v-if="fullyAuthenticated"
        @selected-organisation="(organisation) => trackAndGoToNextStep(organisation, true)"
      />

      <restricted-notification v-if="!fullyAuthenticated" :scopes="scopes"/>

      <!-- accordion with applications AND/OR scopes AND/OR search-for-organisation -->
      <telia-accordion indented="true" v-if="applications.length > 0 || scopes.length > 0">
        <telia-accordion-item
          v-if="applications.length > 0"
          :heading="t('mainMenuStep.pendingApplications.heading') + ` (${applications.length})`"
        >
          <pending-applications :applications="applications"></pending-applications>
        </telia-accordion-item>
        <telia-accordion-item
          v-if="scopes.length > 0"
          :heading="t('mainMenuStep.currentScopes.heading') + ` (${scopes.length})`"
        >
          <current-scopes :scopes="scopes" />
        </telia-accordion-item>
        <telia-accordion-item
          v-if="fullyAuthenticated"
          :heading="t('mainMenuStep.searchOrganisation.heading')"
        >
          <search-for-organisation
            @selected-organisation="(organisation) => trackAndGoToNextStep(organisation, false)"
          />
        </telia-accordion-item>
      </telia-accordion>

      <!-- if search is the only option, do not show as accordion -->
      <div v-else-if="fullyAuthenticated">
        <telia-heading tag="h3" variant="title-100">{{
          t("mainMenuStep.searchOrganisation.aloneHeading")
        }}</telia-heading>
        <search-for-organisation
          @selected-organisation="(organisation) => trackAndGoToNextStep(organisation, false)"
        />
      </div>

      <!--
        v-else: neither fully authenticated, nor any applications or scopes. nothing to show!
        this should have been prevented by parent
      -->
    </div>
  </div>
</template>

<script setup lang="ts">
import { translateMixin, translateSetup } from "../../locale";
import { PropType, ref, Ref } from "vue";

import * as analytics from "@telia/b2b-web-analytics-wrapper";

import type { Scope, Organisation } from "../../services/organisation-service";

import restrictedNotification from "./main-menu/items/restricted-notification.vue";
import suggestedOrganisations from "./main-menu/items/suggested-organisations.vue";
import pendingApplications from "./main-menu/items/pending-applications.vue";
import currentScopes from "./main-menu/items/current-scopes.vue";
import searchForOrganisation from "./main-menu/items/search-for-organisation.vue";
import {
  getGa4OrganisationRegistrationParams,
  getGa4StepParams,
  trackGa4,
} from "@telia/b2b-analytics";


translateSetup(["mybusiness"]);
const t = translateMixin.methods.t;

const props = defineProps({
  personNamePromise: { type: Object as PropType<Promise<string>>, required: true },
  fullyAuthenticated: { type: Boolean, required: true },
  scopes: { type: Object as PropType<Array<Scope>>, required: true },
  applications: { type: Object as PropType<Array<Organisation>>, required: true },
});

let loading: Ref<boolean> = ref(true);
let personName: Ref<string | null> = ref(null);

props.personNamePromise
  .then((personNameFromBackend) => {
    personName.value = personNameFromBackend;
  })
  .catch(() => {
    personName.value = null;
  })
  .finally(() => {
    loading.value = false;
  });

const emit = defineEmits<{
  (e: "go-to-next-step", organisation: Organisation, isSuggestion: boolean): void;
}>();

const trackAndGoToNextStep = (organisation: Organisation, isSuggestion: boolean) => {
  if (isSuggestion) {
    analytics.trackEvent(
      analytics.category.REGISTER_ACCOUNT,
      analytics.action.SELECTED,
      analytics.label.CHOSEN_COMPANY
    );
  } else {
    analytics.trackEvent(
      analytics.category.REGISTER_ACCOUNT,
      analytics.action.SELECTED,
      analytics.label.CHOSEN_COMPANY_SEARCHED
    );
  }

  const stepOption = isSuggestion ? "company_from_list" : "company_new_application";
  trackGa4("account_registration_progress", {
    ...getGa4OrganisationRegistrationParams(),
    ...getGa4StepParams("company_select", 3, stepOption),
  });

  emit("go-to-next-step", organisation, isSuggestion);
};
</script>

<style lang="scss" scoped>
@import "~@teliads/components/foundations/spacing/variables";

telia-heading,
.heading-skeleton {
  margin-bottom: $telia-spacing-32;
}

.heading-skeleton {
  height: 3.2em;
}

.accordion-skeleton {
  height: 6.4em;
}
</style>
