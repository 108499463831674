<template>
  <telia-grid>
    <telia-row>
      <telia-col width="0" width-lg="2"
        ><!--dummy column to center align the big one in desktop views--></telia-col
      >
      <telia-col width="12" width-lg="8"
        ><div class="step-frame"><slot></slot></div
      ></telia-col>
      <telia-col width="0" width-lg="2"
        ><!--dummy column to center align the big one in desktop views--></telia-col
      >
    </telia-row>
  </telia-grid>
</template>

<script setup lang="ts"></script>

<style lang="scss" scoped>
@import "~@teliads/components/foundations/borders/mixins";
@import "~@teliads/components/foundations/colors/variables";
@import "~@teliads/components/foundations/spacing/variables";

.step-frame {
  margin: $telia-spacing-32 $telia-spacing-0 $telia-spacing-64;
  padding: $telia-spacing-32;
  @include telia-border-radius("large");
  @include telia-border("gray-200", "xs", "all");
  background-color: $telia-white;
}
</style>
