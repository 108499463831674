<template>
  <div>
    <div class="notification-wrapper">
      <telia-notification status="warning" heading-tag="h3">
        <span slot="heading">
          <telia-visually-hidden>
            {{
              t(
                scopes.length > 0
                  ? "restrictedLogin.limitedOptions_hasScopes_shouldUpgradeAccountToBankId.status"
                  : "restrictedLogin.limitedOptions_noScopes_shouldLogInWithBankId.status"
              )
            }}
          </telia-visually-hidden>
          {{
            t(
              scopes.length > 0
                ? "restrictedLogin.limitedOptions_hasScopes_shouldUpgradeAccountToBankId.heading"
                : "restrictedLogin.limitedOptions_noScopes_shouldLogInWithBankId.heading"
            )
          }}
        </span>
        <span slot="content">
          <telia-p>
            <span v-if="scopes.length > 0">
              {{
                t("restrictedLogin.limitedOptions_hasScopes_shouldUpgradeAccountToBankId.content1")
              }}<a :href="`${B2B_BASE_URL}/${scopes[0].id}/installningar/minprofil`">{{
                t("restrictedLogin.limitedOptions_hasScopes_shouldUpgradeAccountToBankId.content2")
              }}</a>{{
                t("restrictedLogin.limitedOptions_hasScopes_shouldUpgradeAccountToBankId.content3")
              }}
            </span>
            <span v-else>
              {{
                t("restrictedLogin.limitedOptions_noScopes_shouldLogInWithBankId.content")
              }}
            </span>
          </telia-p>
        </span>
      </telia-notification>
    </div>
    <div class="log-out-button-wrapper">
      <!--
        This button SHOULD be full-width on smaller screens, but can't find a way to reasonably implement it in Voca.
        Should be easier in purpur (set className to a class with width:100% and some media query)
      -->
      <telia-button
        v-if="scopes.length <= 0"
        type="button"
        size="md"
        variant="primary"
        @click="logOut"
      >{{ t("restrictedLogin.logOut") }}</telia-button>
    </div>
  </div>
</template>

<script setup lang="ts">
import { PropType } from "vue";

import { translateMixin, translateSetup } from "../../../../locale";

import { B2B_BASE_URL } from "@telia/b2b-utils";

import type { Scope } from "../../../../services/organisation-service";
import { logOut } from "../../../../services/user-service";

translateSetup(["mybusiness"]);
const t = translateMixin.methods.t;

defineProps({
  scopes: { type: Object as PropType<Array<Scope>>, required: true },
});
</script>

<style lang="scss" scoped>
@import "~@teliads/components/foundations/spacing/variables";

.notification-wrapper,
.log-out-button-wrapper {
  margin-bottom: $telia-spacing-32;
}
</style>
