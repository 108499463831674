import { corpCustomerRegistration, corpScopeInformation } from "@telia/b2b-rest-client";

import {
  mapAvailableScopes,
  mapOrganisationSearchResult,
  mapPendingApplications,
  mapSubmitApplicationError,
  mapSubmitApplicationSuccess,
  mapSuggestedOrganisations,
  normalizeOrganisation,
} from "./organisation-mapper";
import type {
  Organisation,
  Scope,
  SearchPromises,
  SubmitResultFromBackend,
} from "./organisation-mapper";

export { isOrganisationNumberValid } from "./organisation-mapper";
export type {
  Organisation,
  Scope,
  SearchPromises,
  SubmitResultFromFlow,
} from "./organisation-mapper";

export const getSuggestedOrganisations = async () => {
  // MOCK
  if (window.localStorage.getItem("telia-mock-suggestions") === "ERROR") {
    throw new Error("mock fail");
  } else if (window.localStorage.getItem("telia-mock-suggestions") === "0") {
    await new Promise((resolve) => setTimeout(resolve, 4000));
    return [];
  } else if (window.localStorage.getItem("telia-mock-suggestions") === "2") {
    await new Promise((resolve) => setTimeout(resolve, 4000));
    return [
      { name: "org 1", organisationNumber: "888888-8888" },
      { name: "org 2", organisationNumber: "999999-9999" },
    ];
  }
  // END OF MOCK

  return mapSuggestedOrganisations(
    await corpCustomerRegistration.OrganisationControllerService.getSuggestedOrganisations()
  );
};

export const getExistingScopes = async (): Promise<Array<Scope>> => {
  // MOCK
  if (window.localStorage.getItem("telia-mock-scopes") === "ERROR") {
    throw new Error("mock fail");
  } else if (window.localStorage.getItem("telia-mock-scopes") === "0") {
    await new Promise((resolve) => setTimeout(resolve, 500));
    return [];
  } else if (window.localStorage.getItem("telia-mock-scopes") === "2") {
    await new Promise((resolve) => setTimeout(resolve, 500));
    return [
      { displayName: "org 1", id: "888888888" },
      { displayName: "org 2", id: "999999999" },
    ];
  }
  // END OF MOCK

  const initialScope = (
    await corpScopeInformation.ScopeControllerService.getScopeForLoggedInUser(
      "LOGGED_IN_USER_NO_SCOPE"
    )
  ).customerScope;

  return mapAvailableScopes(
    initialScope
      ? (await corpScopeInformation.ScopeControllerService.getAvailableScopes(initialScope))
          .available ?? []
      : []
  );
};

export const getPendingApplications = async (): Promise<Array<Organisation>> => {
  // MOCK
  if (window.localStorage.getItem("telia-mock-applications") === "ERROR") {
    throw new Error("mock fail");
  } else if (window.localStorage.getItem("telia-mock-applications") === "0") {
    await new Promise((resolve) => setTimeout(resolve, 500));
    return [];
  } else if (window.localStorage.getItem("telia-mock-applications") === "2") {
    await new Promise((resolve) => setTimeout(resolve, 500));
    return [
      { name: "org 1", organisationNumber: "888888-8888" },
      { name: "org 2", organisationNumber: "999999-9999" },
    ];
  }
  // END OF MOCK

  // note: the BE has a filter and only returns OPEN application.
  return mapPendingApplications(
    (
      await corpCustomerRegistration.ApplicationRequestControllerService.getApplicationsForLoggedInUser(
        "LOGGED_IN_USER_NO_SCOPE"
      )
    ).applicationRequests ?? []
  );
};

/**
 * Returns either an object if one is found, null if none is found
 * @param organisationNumber
 * @returns two promises
 * The cancelable can be cancelled (in that case, the result promises will never resolve).
 * The result promise is ran after cancelable, and will return a simplified object.
 */
export const searchForOrganisation = (organisationNumber: string): SearchPromises => {
  // return this one so that it can be cancelled
  const cancelable = corpCustomerRegistration.OrganisationControllerService.searchOrganisation({
    organisationNumber: normalizeOrganisation(organisationNumber),
  });

  // we need to transform the backend object but the ".then" on a
  // CancelablePromise returns a normal Promise which can't be canceled,
  // so we return both
  let result = cancelable.then(
    (backendOrganisationWrapper: corpCustomerRegistration.SearchOrganisationResponse) =>
      mapOrganisationSearchResult(backendOrganisationWrapper)
  );

  // MOCK
  if (window.localStorage.getItem("telia-mock-search") === "1") {
    result = result.catch(() => {
      if (parseInt(organisationNumber.charAt(0)) <= 1) {
        throw new Error("mock error");
      } else if (parseInt(organisationNumber.charAt(0)) <= 4) {
        return null;
      } else {
        return {
          name: "John Doe's bakery***",
          organisationNumber: normalizeOrganisation(organisationNumber),
        };
      }
    });
  }
  // END OF MOCK

  return { cancelable, result };
};

export const submitApplication = async (
  organisationNumber: string,
  phoneNumber: string,
  email: string
): Promise<SubmitResultFromBackend> => {
  // debug code to override the value during local development
  if (window.localStorage.getItem("telia-mock-submit") === "ERROR") {
    throw new Error("mock fail");
  } else if (window.localStorage.getItem("telia-mock-submit")) {
    await new Promise((resolve) => setTimeout(resolve, 4000));
    return {
      result: window.localStorage.getItem("telia-mock-submit"),
      invalidEmailAddress: window.localStorage.getItem("telia-mock-invalidemailaddress") === "1",
      invalidPhoneNumber: window.localStorage.getItem("telia-mock-invalidphonenumber") === "1",
      scopeId: window.localStorage.getItem("telia-mock-scopeid")
        ? window.localStorage.getItem("telia-mock-scopeid")
        : undefined,
    } as SubmitResultFromBackend;
  }
  // end of debug code

  try {
    return mapSubmitApplicationSuccess(
      await corpCustomerRegistration.ApplicationRequestV2ControllerService.createApplication({
        organisationNumber: organisationNumber,
        emailAddress: email,
        phoneNumber: phoneNumber,
      })
    );
  } catch (error: unknown) {
    return mapSubmitApplicationError(error);
  }
};
