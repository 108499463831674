<template>
  <telia-p>
    <banner-and-stepper :current-step="currentStep" @go-to-step="goToStep">
      <!-- step 0 is not relevant when not authenticated-->
      <template v-slot:main-menu>
        <main-menu-step
          @go-to-next-step="
            (organisation, isSuggestion) => goToConfirmationStep(organisation, isSuggestion)
          "
          :person-name-promise="personNamePromise"
          :fully-authenticated="fullyAuthenticated"
          :scopes="scopes"
          :applications="applications"
        />
      </template>
      <template v-slot:additional-data-and-confirmation>
        <!-- caution! user information has to be finished before proceeding "additional-data...." step is made visible below,
          otherwise defaults will break. This is currently ensured by not showing anything on "main-menu" until that has been done -->
        <additional-data-and-confirmation-step
          v-if="selectedOrganisation"
          :organisation="selectedOrganisation!"
          :default-email="defaultEmail"
          :default-phone-number="defaultPhoneNumber"
          :is-suggestion="isSelectedFromList"
          @submitted-application="
            (submitResult: SubmitResultFromFlow) => $emit('finish-flow', submitResult)
          "
        />
      </template>
    </banner-and-stepper>
  </telia-p>
</template>

<script setup lang="ts">
import { PropType, ref, Ref } from "vue";

import { logError } from "@telia/b2x-logging";

import type { Organisation, Scope, SubmitResultFromFlow } from "../services/organisation-service";
import { getUserInformation } from "../services/user-service";

import bannerAndStepper from "./banner-and-stepper.vue";
import mainMenuStep from "./steps/main-menu-step.vue";
import additionalDataAndConfirmationStep from "./steps/additional-data-and-confirmation-step.vue";

defineProps({
  fullyAuthenticated: { type: Boolean, required: true },
  scopes: { type: Object as PropType<Array<Scope>>, required: true },
  applications: { type: Object as PropType<Array<Organisation>>, required: true },
});

let currentStep: Ref<1 | 2> = ref(1);
let selectedOrganisation: Ref<Organisation | null> = ref(null);
const isSelectedFromList: Ref<boolean | undefined> = ref();

let getUserInformationPromise: ReturnType<typeof getUserInformation> = getUserInformation().catch(
  () => {
    logError(
      "b2b-organisation-registration",
      "Failed to get the details about the logged in user, will not provide defaults etc"
    );
    return {
      // name: undefined, email: undefined, phoneNumber: undefined
    };
  }
);

let personNamePromise: Ref<Promise<string>> = ref(
  getUserInformationPromise.then((userInformation) => {
    return userInformation.name ?? "";
  })
);

let defaultEmail: Ref<string> = ref("");
getUserInformationPromise.then((userInformation) => {
  if (userInformation.email && userInformation.email.length > 0) {
    defaultEmail.value = userInformation.email;
  }
});

let defaultPhoneNumber: Ref<string> = ref("+46 "); // intentional space after country code
getUserInformationPromise.then((userInformation) => {
  if (userInformation.phoneNumber && userInformation.phoneNumber.length > 0) {
    defaultPhoneNumber.value = userInformation.phoneNumber;
  }
});

const goToConfirmationStep = (organisation: Organisation, isSuggestion: boolean) => {
  selectedOrganisation.value = organisation;
  isSelectedFromList.value = isSuggestion;
  currentStep.value = 2;
};

const goToStep = (number: number) => {
  if (number === 1) {
    currentStep.value = number;
  }
  // other cases are forbidden, do nothing
};
</script>

<style lang="scss" scoped></style>
