<template>
  <div>
    <telia-heading tag="h2" variant="title-300">{{
      t("additionalDataAndConfirmationStep.heading")
    }}</telia-heading>

    <telia-p>
      <summary-text :organisation="organisation" />
    </telia-p>

    <telia-form @submit.prevent="submit">
      <telia-text-input
        :disabled="submitting"
        :value="phoneNumber"
        :label="t('additionalDataAndConfirmationStep.phoneNumberLabel')"
        inputmode="tel"
        autocomplete="tel"
        type="tel"
        required="true"
        :required-error-message="t('additionalDataAndConfirmationStep.phoneNumberEmpty')"
        :errorMessage="
          invalidPhoneNumber ? t('additionalDataAndConfirmationStep.phoneNumberInvalid') : undefined
        "
        @input="
          phoneNumber = $event.target.value;
          invalidPhoneNumber = false;
        "
      />
      <telia-text-input
        :disabled="submitting"
        :value="email"
        :label="t('additionalDataAndConfirmationStep.emailAddressLabel')"
        inputmode="email"
        autocomplete="email"
        type="email"
        required="true"
        :required-error-message="t('additionalDataAndConfirmationStep.emailAddressEmpty')"
        :email-error-message="t('additionalDataAndConfirmationStep.emailAddressInvalid')"
        :errorMessage="
          invalidEmailAddress
            ? t('additionalDataAndConfirmationStep.emailAddressInvalid')
            : undefined
        "
        @input="
          email = $event.target.value;
          invalidEmailAddress = false;
        "
      />

      <!-- 
        WCAG guidlines says to avoid opening links in new window by default, BUT,
        it lists "interrupting a workflow" as a valid exception for opening in a new window.
        WCAG also says to give advance warning before opening a link in a new window, so the link labels should contain
        "(opens in new window)" or similar.
      -->
      <telia-p>
        <telia-link :href="B2B_TERMS_URL" target="_blank">{{
          t("additionalDataAndConfirmationStep.conditionsLinkLabel")
        }}</telia-link>
      </telia-p>

      <!--
        having the link inside the checkbox label is a bit broken.
        It is confusing from a11y perspective (should the link or the checkbox receive the click?),
        telia-checkbox is also a bit broken so it seems random which of them receives the click
      -->
      <telia-checkbox
        :disabled="submitting"
        required="true"
        :required-error-message="t('additionalDataAndConfirmationStep.conditionsNotAccepted')"
      >
        {{ t("additionalDataAndConfirmationStep.acceptConditionsLabel") }}
      </telia-checkbox>

      <b2x-loading-button type="submit" variant="primary" :is-loading="submitting">
        {{ t("additionalDataAndConfirmationStep.submit") }}
      </b2x-loading-button>
    </telia-form>
  </div>
</template>

<script setup lang="ts">
import { ref, Ref } from "vue";
import { translateMixin, translateSetup } from "../../locale";
import * as analytics from "@telia/b2b-web-analytics-wrapper";

import { B2B_TERMS_URL } from "@telia/b2b-utils";
import { logError } from "@telia/b2x-logging";

import { submitApplication } from "../../services/organisation-service";

import summaryText from "./additional-data-and-confirmation-step/summary-text.vue";
import {
  getGa4OrganisationRegistrationParams,
  getGa4StepParams,
  trackGa4,
} from "@telia/b2b-analytics";

translateSetup(["mybusiness"]);
const t = translateMixin.methods.t;

// INPUT to component from previous step = props
const props = defineProps({
  organisation: { type: Object, required: true },
  defaultEmail: { type: String, required: false },
  defaultPhoneNumber: { type: String, required: false },
  isSuggestion: { type: Boolean, required: false },
});

// STATE OF this step
let email: Ref<string> = ref(props.defaultEmail ?? "");
let phoneNumber: Ref<string> = ref(props.defaultPhoneNumber ?? "");

// false on these two mean "maybe"
let invalidEmailAddress: Ref<boolean> = ref(false);
let invalidPhoneNumber: Ref<boolean> = ref(false);

let submitting: Ref<boolean> = ref(false);

// OUTPUT of this step = an event
const emit = defineEmits(["submitted-application"]);

// Do the submit, handle validation errors
const submit = async () => {
  submitting.value = true;
  analytics.trackEvent(
    analytics.category.REGISTER_ACCOUNT,
    analytics.action.SUBMIT,
    analytics.label.BANK_ID
  );

  const stepOption = props.isSuggestion ? "company_from_list" : "company_new_application";

  trackGa4("account_registration_progress", {
    ...getGa4OrganisationRegistrationParams(),
    ...getGa4StepParams("contact_info", 4, stepOption),
  });

  try {
    const result = await submitApplication(
      props.organisation.organisationNumber,
      phoneNumber.value,
      email.value
    );
    if (result.result === "INVALID_INPUT") {
      if (result.invalidEmailAddress) {
        invalidEmailAddress.value = true;
      }
      if (result.invalidPhoneNumber) {
        invalidPhoneNumber.value = true;
      }
      submitting.value = false;
    } else {
      emit("submitted-application", result);
    }
  } catch {
    logError("b2b-organisation-registration", "Unexpected error when submitting application");
    emit("submitted-application", { result: "UNEXPECTED_ERROR" });
  }
};
</script>

<style lang="scss" scoped>
@import "~@teliads/components/foundations/spacing/variables";

telia-heading {
  margin-bottom: $telia-spacing-16;
}
telia-p {
  margin-bottom: $telia-spacing-16;
}
</style>
