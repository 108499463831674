<template>
  <button
    @click="$emit('selected-organisation', organisation)"
    :aria-label="
      t('mainMenuStep.selectOrganisation', {
        name: organisation.name,
        organisationNumber: organisation.organisationNumber,
      })
    "
  >
    <div class="button-content">
      <div class="information">
        <telia-p class="name"
          ><span class="name-content">{{ organisation.name }}</span></telia-p
        >
        <telia-p class="organisation-number">{{ organisation.organisationNumber }}</telia-p>
      </div>
      <div class="icon">
        <telia-icon name="arrow-right" />
      </div>
    </div>
  </button>
</template>

<script setup lang="ts">
import { translateMixin, translateSetup } from "../../../locale";
import { Organisation } from "../../../services/organisation-service";
import type { PropType } from "vue";

translateSetup(["mybusiness"]);
const t = translateMixin.methods.t;

defineProps({
  organisation: { type: Object as PropType<Organisation>, required: true },
});
</script>

<style lang="scss" scoped>
@import "~@teliads/components/foundations/borders/mixins";
@import "~@teliads/components/foundations/colors/variables";
@import "~@teliads/components/foundations/spacing/variables";
@import "~@teliads/components/foundations/typography/variables.scss";

button {
  @include telia-border-radius("small");
  @include telia-border("gray-400", "xs", "all");
  background-color: $telia-white;
  cursor: pointer;
  text-align: left;
  width: 100%;
  font-size: 1.6rem;

  .button-content {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: $telia-spacing-16;

    .information {
      .name {
        margin-bottom: $telia-spacing-8;

        .name-content {
          font-weight: $telia-typography-weight-bold;
        }
      }
    }
  }
}
</style>
