<template>
  <div>
    <telia-heading tag="h2" variant="title-300">{{
      t("preAuthenticationStep.heading")
    }}</telia-heading>
    <telia-p>
      {{ t("preAuthenticationStep.information") }}
    </telia-p>
    <form
      data-testid="login-form"
      name="login-form"
      method="post"
      :action="`/.api/corp-login/login?language=${currentLanguage()}`"
      @submit="onSubmit()"
    >
      <input type="hidden" name="targetUrl" :value="targetUrl" autoComplete="off" />
      <input type="hidden" name="forceMethod" value="BANKID" autoComplete="off" />

      <!--
        plenty of weirdness here, because:
        1. not using Voca icon library because it does not have the bankid logo 
        2. not using b2x-loading-button because it does not currently work well with icons
        3. lots of hardcoded CSS positions and margins, because the bankid logo has a lot of whitespace margins
        4. v-show, because with v-if, b2x-spinner often does not load quick enough
      -->
      <telia-button type="submit" variant="primary" :disabled="submitting">
        <div slot="left" class="button-icon" role="presentation">
          <div v-show="submitting" class="spinner-wrapper">
            <b2x-spinner />
          </div>
          <div v-show="!submitting" class="bankid-logo-wrapper">
            <img src="../../assets/bankIdIcon.svg" alt="" />
          </div>
        </div>
        {{ t("preAuthenticationStep.logIn") }}
      </telia-button>
    </form>
  </div>
</template>

<script setup lang="ts">
import { currentLanguage } from "@telia/b2b-i18n";
import { translateMixin, translateSetup } from "../../locale";
import { ref, Ref } from "vue";
import * as analytics from "@telia/b2b-web-analytics-wrapper";
import {
  getGa4OrganisationRegistrationParams,
  getGa4StepParams,
  trackGa4,
} from "@telia/b2b-analytics";

translateSetup(["mybusiness"]);
const t = translateMixin.methods.t;

const targetUrl = window.location.toString();

let submitting: Ref<boolean> = ref(false);

const onSubmit = () => {
  submitting.value = true;
  analytics.trackEvent(
    analytics.category.REGISTER_ACCOUNT,
    analytics.action.INITIATED,
    analytics.label.BANK_ID
  );

  trackGa4("account_registration_identify", {
    ...getGa4OrganisationRegistrationParams(),
    ...getGa4StepParams("account_registration_identify", 2, undefined),
  });

  return true;
};
</script>

<style lang="scss" scoped>
@import "~@teliads/components/foundations/spacing/variables";

telia-heading {
  margin-bottom: $telia-spacing-16;
}
telia-p {
  margin-bottom: $telia-spacing-32;
}

.button-icon {
  height: calc(2.4rem * var(--voca-rem-multiplier));
  width: calc(2.4rem * var(--voca-rem-multiplier));
  margin-left: calc(0.4rem * var(--voca-rem-multiplier));
  margin-right: calc(1.2rem * var(--voca-rem-multiplier));
}

.spinner-wrapper {
  position: absolute;
  left: calc(0 * var(--voca-rem-multiplier));
  top: calc(0 * var(--voca-rem-multiplier));
  height: calc(2.4rem * var(--voca-rem-multiplier));
  width: calc(2.4rem * var(--voca-rem-multiplier));
}

.bankid-logo-wrapper {
  position: absolute;
  left: calc(1.5rem * var(--voca-rem-multiplier));
  top: calc(0.9rem * var(--voca-rem-multiplier));
  height: calc(4.8rem * var(--voca-rem-multiplier));
  width: calc(4.8rem * var(--voca-rem-multiplier));
}
</style>
