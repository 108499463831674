<template>
  <div class="wrapper">
    <!-- skeleton-->
    <div v-if="precheckState === 'LOADING'">
      <!-- blank page with correct background is probably better than skeleton -->
    </div>

    <!--
      User has not logged in.
      Show the first half of the flow (step 1 only)
    -->
    <pre-authentication-flow v-else-if="precheckState === 'NOT_LOGGED_IN'" />

    <!--
      User has logged in with BankId (user will always have options, at least search should always be available),
      OR user has logged in with BankID and has options (either one or more scope = existing memberships, OR, one or more ongoing applications)
      Show the second half of the flow (step 2+)
    -->
    <post-authentication-flow-and-result
      v-else-if="precheckState === 'OK' || precheckState === 'RESTRICTED_LOGIN'"
      :fully-authenticated="precheckState === 'OK'"
    />

    <!--
      The flow is temporarily disabled.
      We don't know if user is logged in or not.
    -->
    <b2x-result-message
      v-else-if="precheckState === 'CLOSED'"
      graphic="error"
      :heading="t('precheckError.closed.heading')"
      :complete-description="t('precheckError.closed.completeDescription')"
      action-type="cta-link"
      :action-label="t('precheckError.closed.publicMyBusinessLink')"
      :link-href="B2B_START_PAGE_URL"
    />

    <!--
      Unexpected error.
      We don't know if user is logged in or not.
    -->
    <b2x-result-message
      v-else
      graphic="technical-error"
      :heading="t('precheckError.unexpectedError.heading')"
      :complete-description="t('precheckError.unexpectedError.completeDescription')"
      action-type="cta-link"
      :action-label="t('precheckError.unexpectedError.publicMyBusinessLink')"
      :link-href="B2B_START_PAGE_URL"
    />
  </div>
</template>

<script setup lang="ts">
import { onUnmounted, ref, Ref } from "vue";

import * as analytics from "@telia/b2b-web-analytics-wrapper";

import type { PrecheckStatus } from "./services/user-service";
import { precheck } from "./services/user-service";
import { logError } from "@telia/b2x-logging";

import postAuthenticationFlowAndResult from "./components/post-authentication-flow-and-result.vue";
import preAuthenticationFlow from "./components/pre-authentication-flow.vue";
import { translateMixin, translateSetup } from "./locale";

import {
  B2B_ORGANISATION_REGISTRATION_BASE_URL,
  B2B_ORGANISATION_REGISTRATION_LOGGED_IN_URL,
  B2B_ORGANISATION_REGISTRATION_NOT_LOGGED_IN_URL,
  B2B_START_PAGE_URL,
} from "@telia/b2b-utils";

translateSetup(["mybusiness"]);
const t = translateMixin.methods.t;

let precheckState: Ref<PrecheckStatus | "LOADING" | "ERROR"> = ref("LOADING");

precheck()
  .then((result) => {
    precheckState.value = result;
  })
  .catch(() => {
    precheckState.value = "ERROR";
    logError("b2b-organisation-registration", "Failed precheck");
  })
  .finally(() => {
    setUrlForNavigation();
    trackPrecheckResultIfBad();
  });

const setUrlForNavigation = () => {
  // set the correct URL. The only purpose of this is to show the correct navigation.
  switch (precheckState.value) {
    case "LOADING":
      // do nothing, leave current URL to reduce navigation flashing
      break;

    case "OK":
    case "RESTRICTED_LOGIN":
      setUrlIfDifferent(B2B_ORGANISATION_REGISTRATION_LOGGED_IN_URL);
      break;

    case "CLOSED": // do not know if user is logged in or not
    case "NOT_LOGGED_IN":
    case "ERROR":
    default:
      setUrlIfDifferent(B2B_ORGANISATION_REGISTRATION_NOT_LOGGED_IN_URL);
      break;
  }
};

const trackPrecheckResultIfBad = () => {
  switch (precheckState.value) {
    case "RESTRICTED_LOGIN":
      analytics.trackEvent(
        analytics.category.REGISTER_ACCOUNT,
        analytics.action.ERROR,
        analytics.label.RESTRICTED_LOGIN_ERROR
      );
      break;
    case "CLOSED":
      analytics.trackEvent(
        analytics.category.REGISTER_ACCOUNT,
        analytics.action.ERROR,
        analytics.label.CLOSED_ERROR
      );
      break;
    case "ERROR":
      analytics.trackEvent(
        analytics.category.REGISTER_ACCOUNT,
        analytics.action.ERROR,
        analytics.label.UNEXPECTED_ERROR
      );
      break;
  }
  // no tracking of success status
};

const setUrlIfDifferent = (newPath: string) => {
  // replace state will trigger a popstate event,
  // this check is needed to prevent infinite loop
  if (window.location.pathname !== newPath) {
    history.replaceState(history.state, "", newPath);
  }
};

const onPopState = () => {
  // popstate happens on every "navigateToUrl" within the sspa app, including many b2b-sspa-links.
  // this check is needed to not interfere when navigating to a different page
  if (window.location.pathname.startsWith(B2B_ORGANISATION_REGISTRATION_BASE_URL)) {
    setUrlForNavigation();
  }
};
window.addEventListener("popstate", onPopState);

onUnmounted(() => {
  window.removeEventListener("popstate", onPopState);
});
</script>

<style lang="scss" scoped>
@import "~@teliads/components/foundations/colors/variables";

.wrapper {
  height: inherit;
  background: $telia-gray-50;
}
</style>
