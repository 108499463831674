<template>
  <!--
    We must use v-html since we need to inject HTML formatting

    Org name/number comes from the outside and may contain characters that must be encoded (escaped) into HTML.
      Normally we would just do double moustach but that would also escape the injected HTML
  -->
  <span
    v-html="
      t('additionalDataAndConfirmationStep.introductionHtml', {
        organisationNameAndNumber: `<strong>${htmlEncodedName} (${htmlEncodedOrganisationNumber})</strong>`,
      })
    "
  ></span>
</template>

<script setup lang="ts">
import { computed, ComputedRef } from "vue";
import { translateMixin, translateSetup } from "../../../locale";

translateSetup(["mybusiness"]);
const t = translateMixin.methods.t;

const props = defineProps({
  organisation: { type: Object, required: true },
});

// Functions to handle rendering of organisation number and name in a safe manner
const htmlEncodedName: ComputedRef<string> = computed<string>(() => {
  return htmlEncode(props.organisation.name);
});
const htmlEncodedOrganisationNumber: ComputedRef<string> = computed<string>(() => {
  return htmlEncode(props.organisation.organisationNumber);
});
/**
 * e.g. takes an unsafe, untrusted string and returns a safe HTML string. E.g. unencodedUntrustedText "hello <3" returns "hello &lt;3"
 */
const htmlEncode = (unencodedUntrustedText: string): string => {
  const div = document.createElement("div");
  div.innerText = unencodedUntrustedText;
  return div.innerHTML; // returns the text encoded into HTML
};
</script>

<style lang="scss" scoped>
@import "~@teliads/components/foundations/typography/variables.scss";

strong {
  font-weight: $telia-typography-weight-bold;
}
</style>
